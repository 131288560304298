import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FullWidthBanner from "../components/fullWidthBanner"
import ContentArea from "../components/contentArea"
import Heading from "../components/heading"
import content from '../../content/homepage.json'

const IndexPage = () => (
    <Layout pageName="home">
        <FullWidthBanner {...content.banner} />
        <ContentArea>
            <Heading {...{ level: 3, content: "About Us" }}/>
            <p>
                We are a clinic of multidisciplinary mental healthcare professionals who seek to promote mental wellness in the community through individual and group treatment, education, counselling, psychiatric care, and team support.
                <br/><br/>
			</p>
        </ContentArea>
    </Layout>
)

export const Head = () => <Seo title="Home" />

export default IndexPage
