import React, { FunctionComponent } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
    bannerContainer: {
        display: 'grid',
        fontFamily: "Open Sans, sans-serif"
    },
    image: {
        gridArea: "1/1",
        minHeight: 500
    },
    contentContainer: {
        margin: 'auto',
        gridArea: "1/1",
        zIndex: 1,
        display: 'flex',
        '@media(min-width: 576px)': {
            width: 540
        },
        '@media(min-width: 768px)': {
            width: 720
        },
        '@media(min-width: 992px)': {
            width: 960
        },
        '@media(min-width: 1200px)': {
            width: 1140
        },
    },
    contentCard: {
        background: '#00000028',
        padding: '0 15px',
        width: '100%',
        '@media(min-width: 768px)': {
            width: '65%'
        },
        '@media(min-width: 992px)': {
            width: '50%'
        },
        '@media(min-width: 1200px)': {
            width: '40%'
        },
        '& p': {
            margin: 20,
            color: 'white',
            fontSize: '16px',
            lineHeight: 1.5,
            letterSpacing: 0,
            textAlign: 'justify'
        }
    },
    cta: {
        display: 'inline-block',
        padding: '0px 28px',
        color: 'white',
        lineHeight: '46px',
        borderRadius: '0px',
        border: '1px solid #e8e8e8',
        cursor: 'pointer',
        transition: 'all 300ms linear 0s',
        margin: '10px 0 20px 20px',
        fontSize: '14px',
        textDecoration: 'none'
    }
})

export interface FullWidthBannerProps {
    image: string,
    cardText: string,
    ctaLabel: string,
    ctaUrl: string
}

const FullWidthBanner: FunctionComponent<FullWidthBannerProps> = (props) => {
    const styles = useStyles()

    return (
        <>
            <div className={styles.bannerContainer}>
                <StaticImage
                    className={styles.image}
                    layout="fullWidth"
                    placeholder="blurred"
                    alt=""
                    src="../images/lavendar.jpg"
                    formats={["auto", "webp", "avif"]}
                />
                <div className={styles.contentContainer}>
                    <div className={styles.contentCard}>
                        <p>{props.cardText}</p>
                        <Link
                            to={props.ctaUrl}
                            className={styles.cta}>
                            {props.ctaLabel}
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FullWidthBanner